console.log("bundle is connected");

import jQuery from "jquery";
window.$ = window.jQuery = jQuery;

import ResizeSensor from 'resize-sensor';
window.ResizeSensor = ResizeSensor;

import StickySidebar from 'sticky-sidebar-v2';
window.StickySidebar = StickySidebar;

import Swiper from 'swiper/';
import Navigation from 'swiper/modules/navigation.min.mjs';
import Thumbs from 'swiper/modules/thumbs.min.mjs';
import EffectFade from 'swiper/modules/effect-fade.mjs';

window.Swiper = Swiper;
window.Navigation = Navigation;
window.Thumbs = Thumbs;
window.EffectFade = EffectFade;

import flatpickr from "flatpickr";
window.flatpickr = flatpickr;
